
export default {
	name: 'detail',
	data() {
		return {
			sex: 'girl',
			newEdit: false,
			name: '',
			years: '',
			title: '暂无',
			user: '', //用户
			ggligtScoreDetail: '', //职业素养
			sqfmydScoreDetail: '', //沟通与协调
			xmzgpfScoreDetail: '', //学习与接受
			yjzxtScoreDetail: '', //整理与完善
			yyglScoreDetail: '', //执行与完成
			agencydirectorScoreDetail: '', //机构主任
			sUBScoreDetail: '' //SUB I
		}
	},

	mounted() {
		this.drawLine();
	},
	created() {
		this.token = this.getCookie('token')
		this.getCrcParticulars()
	},
	methods: {
		getCrcParticulars() {
			var _this = this;
			console.log(this.$route.params.id)
			_this.$http.post('/API/PRO/selectCRCUserScore', {
				CRCUserId: this.$route.params.id
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					//							console.log(response)
					if (response.data.code == 200) {
						//							console.log(response)
						_this.user = response.data.data.user;
						_this.ggligtScoreDetail = response.data.data.ggligtScoreDetail;
						_this.sqfmydScoreDetail = response.data.data.sqfmydScoreDetail;
						_this.xmzgpfScoreDetail = response.data.data.xmzgpfScoreDetail;
						_this.yjzxtScoreDetail = response.data.data.yjzxtScoreDetail;
						_this.yyglScoreDetail = response.data.data.yyglScoreDetail;
						_this.agencydirectorScoreDetail = response.data.data.agencydirectorScoreDetail;
						_this.sUBScoreDetail = response.data.data.sUBScoreDetail;
					}
				})
		},
		toCrcMena() {
			this.$router.push('/assess')
		},
		toPro(id) {
			this.$router.push({ name: 'somProjectParticulars', params: { id: id } })
			this.$store.commit('getId', id)
		},
		drawLine() {
			var _this = this;
			// 基于准备好的dom，初始化echarts实例
			// let echar1 = this.$echarts.init(document.getElementById("echar-1"))
			let echar = this.$echarts.init(document.getElementById("echar"));
			// 绘制图表
			_this.$http.post(
				"/API/PRO/selectCRCUserScore", {
				CRCUserId: this.$route.params.id
			}, {
				headers: {
					token: _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//console.log(response)
						echar.setOption({
							radar: {
								radius: '50%',
								center: ['50%', '50%'],
								//						        shape: 'circle',
								name: {
									textStyle: {
										color: '#333'
									}
								},
								splitArea: {
									areaStyle: {
										color: '#D7F6FB'
									}
								},
								indicator: [
									{ name: '职业素养', max: 100 },
									{ name: '沟通与协调', max: 100 },
									{ name: '学习与接受', max: 100 },
									{ name: '整理与完善', max: 100 },
									{ name: '执行与完成', max: 100 }
								],
								axisLine: {
									lineStyle: {
										color: '#fff'
									}
								},
								splitLine: {
									lineStyle: {
										color: '#fff'
									}
								}
							},
							series: {
								type: 'radar',
								// areaStyle: {normal: {}},
								data: [
									{
										value: [response.data.data.user.avgZzsy,
										response.data.data.user.avgGtyxt,
										response.data.data.user.avgXxyjs,
										response.data.data.user.avgZlyws,
										response.data.data.user.avgZxywc],
										areaStyle: {
											normal: {
												color: '#5BD0E2'
											}
										},
										itemStyle: {
											normal: {
												color: '#5BD0E2'
											}
										}
									}
								]
							},
						});
					}
				});
		}
	}
}
